import React, { useEffect } from 'react'
import { LiveChatLoaderProvider, Messenger } from 'react-live-chat-loader'

const facebookPageId = "106547277362276";

const ChatBox = () => {
    return (
        <div role="button" aria-label="chatbox">
            <LiveChatLoaderProvider 
                provider="messenger" 
                providerKey={`${facebookPageId}`} 
                locale="en_PH" >
                <Messenger color="#7026b1"/>
            </LiveChatLoaderProvider>
        </div>
    )
}

export default ChatBox;