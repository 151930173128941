// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-pages-advocacy-js": () => import("./../../../src/templates/pages/advocacy.js" /* webpackChunkName: "component---src-templates-pages-advocacy-js" */),
  "component---src-templates-pages-faqs-js": () => import("./../../../src/templates/pages/faqs.js" /* webpackChunkName: "component---src-templates-pages-faqs-js" */),
  "component---src-templates-pages-home-js": () => import("./../../../src/templates/pages/home.js" /* webpackChunkName: "component---src-templates-pages-home-js" */),
  "component---src-templates-pages-over-the-counter-js": () => import("./../../../src/templates/pages/over-the-counter.js" /* webpackChunkName: "component---src-templates-pages-over-the-counter-js" */),
  "component---src-templates-pages-prescription-medications-js": () => import("./../../../src/templates/pages/prescription-medications.js" /* webpackChunkName: "component---src-templates-pages-prescription-medications-js" */)
}

